@import '../../../scss/shared';

.navbar {
  background-color: $primary;
  box-shadow: 0 0 5px 5px rgba($plain-black, 0.5);

  a.navbar-item {
    color: $plain-white;

    img {
      max-height: none;

      @media screen and (max-width: 600px) {
        
      }
    }
  }

  .navbar-brand {
    font-size: 1.75rem;
    font-weight: 600;

    img {
      margin-right: 15px;
    }

    @media screen and (max-width: 600px) {
      font-size: 1.25rem;

      img {
        width: 100px;
      }
    }
  }
}