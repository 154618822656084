@import '../../../scss/shared';

.hero {
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($plain-black, 0.5);
    z-index: 1;
  }

  &__video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &-body {
    position: relative;
    z-index: 5;
  }

  &__title {
    font-size: 4rem;
    font-weight: 600;
    color: $plain-white;
    text-align: center;

    @media screen and (max-width: 600px) {
      font-size: 2.5rem;
    }
  }

  &__subtitle {
    font-size: 2.5rem;
    color: $plain-white;
    text-align: center;
    margin-top: 2rem;

    @media screen and (max-width: 600px) {
      font-size: 1.5rem;
    }
  }

  &.is-fullheight-with-navbar {
    min-height: calc(100vh - 7rem);

    @media screen and (max-width: 600px) {
      min-height: calc(100vh - 5rem);
    }
  }
}