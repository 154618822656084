:root {
  font-size: 18px;
}

body {
  font-family: 'PT Sans', Arial, Helvetica, sans-serif;
  font-size: 1rem;

  a {
    color: $primary;

    &:hover {
      color: $secondary;
      text-decoration: underline;
    }
  }

  .emphasis {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: -2rem;

    + h4 {
      font-size: 1.25rem;
    }
  }
}