@import '../../../scss/shared';

.schedule {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 500px;
  width: 500px;
  background-color: rgba($secondary, 0.25);
  border-radius: 50%;
  margin-bottom: -25%;

  &-wrapper {
    padding: 5rem 0;
  }

  &__inner {
    position: relative;
    z-index: 5;
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    color: $secondary;

    @media screen and (max-width: 600px) {
      font-size: 1.5rem;
    }
  }

  &__item {
    font-size: 1.75rem;

    @media screen and (max-width: 600px) {
      font-size: 1.25rem;
    }
  }

  &--last {
    margin-bottom: 0;
  }

  &--right {
    float: right;
  }

  @media screen and (max-width: 600px) {
    height: 400px;
    width: 100%;
    background: none;

    &::before {
      content: '';
      display: block;
      height: 400px;
      width: 400px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba($secondary, 0.25);
      border-radius: 50%;
      z-index: 1;
    }
  }
}