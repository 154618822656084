.section {

  &__title {
    font-size: 2.2rem;
    font-weight: 600;
    color: $primary;
  }

  &--white-bg {
    background-color: $plain-white;
  }

  @media screen and (max-width: 1024px) {
    position: relative;
    overflow: hidden;
  }
}