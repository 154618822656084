@import '../../../scss/shared';

.footer {
  background-color: $secondary;
  color: $plain-white;
  padding: 3rem 1rem;

  a {
    font-weight: 600;
    color: $plain-white;

    &:hover {
      text-decoration: underline;
      color: $plain-white;
    }
  }

  &__link {

    &:not(:last-child) {
      padding-right: 0.75rem;
      border-right: 1px solid $plain-white;
      margin-right: 0.75rem;
    }

    &:hover {
      text-decoration: underline;
      color: $plain-white;
    }
  }
}